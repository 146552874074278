<template>
  <div class="pb-2">
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder mb-1">
        {{ $t('Supplier and Customer Accounts') }}
      </h4>
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :search-props="getSearchProps()"
      :is-auto-suggest="true"
      :is-delete="true"
      :auto-suggest-add-params="autoSuggestAddParams()"
      :filter-class="new Customers()"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getCustomerInAccountPage`
      }"
      :row-clicked="redirectHomeCustomersView"
      :has-action="true"
      :create-page-url="{ name: 'home-customers-create' }"
      :create-btn-dashed="true"
      :table-suggestion-configs="suggestionTableColumns"
      :is-suggestion-table="true"
      :auto-suggest-style="{width:'380px'}"
      :is-reload-on-select-suggest-item="false"
      trash-list-path="home-customers-trash-list"
      :can-create="ACCESS_ABILITY_FOR_ACCOUNTS"
      :can-show-trash-list="ACCESS_ABILITY_FOR_ACCOUNTS"
      :section-configs="{
        default:{
          onSelected: null,
        },
        name: 'default', type: 'auto-suggest-customer-section', limit: 3
      }"
      @searchSelected="pushToSelectedItemByRouter"
      @exportCustomerInfo="exportActionsForBatch({
        btnTitle: 'Export Customer Information',
        actionType: 'customer_information',
      })"
      @exportCustomerAddress="exportActionsForBatch({
        btnTitle: 'Export Customer Addresses',
        actionType: 'customer_addresses',
      })"
      @exportCustomerContacts="exportActionsForBatch({
        btnTitle: 'Export Customer Contacts',
        actionType: 'customer_contacts',
      })"
      @update-batch-selected="handleBatchSelected"
    >
      <template #cell(cust_name_dba)="{ data }">
        <b-link
          :to="{ name: 'home-customers-view' , params: { id: data.item.id }}"
          class="text-body text-wrap"
        >
          <feather-icon
            :icon="checkIconType(data)"
            size="16"
            style="margin-bottom: 5px;"
          />
          {{ getCustomerName(data.item) }}
        </b-link>
      </template>
      <template #cell(default_contact)="{ data }">
        <b-link
          :to="{ name: 'home-customers-contacts' , params: { id: data.item.id }}"
          class="text-body text-wrap"
        >
          {{ getData(data, 'fullname') }}
        </b-link>
      </template>
      <template #cell(phone_one)="{data: {item}}">
        <b-link
          :to="{ name: 'home-customers-contacts' , params: { id: item.id }}"
          class="text-body text-wrap"
        >
          {{ item.default_contact ? getPhoneNumber(item.default_contact) : '—' }}
          <!--        {{ item.default_contact.cell_no | phone }}-->
        </b-link>
      </template>
      <template #cell(status)="{ data }">
        {{ data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #table-top-right-side-extras-prev="{ data }">
        <actions
          icon-name="LExportIcon"
          title="Export"
          :b-drop-down-items="exportImportButtonItems"
          @actionHandler="exportActions"
        />
        <actions
          icon-name="LImportIcon"
          title="Import"
          :b-drop-down-items="exportImportButtonItems"
          @actionHandler="importActions"
        />
      </template>
      <template #cell(actions)="{ data }">
        <div class="d-flex align-items-center">
          <v-select
            id="countryList"
            v-model="customers[data.index].action"
            class="v-select--custom selectInput flex-grow-1"
            label="label"
            :options="supplierActionList"
            @input="onSelectAction"
            @open="isRedirectCustomerPage = false"
          />
          <b-button
            style="padding: 10px; margin-left: 10px"
            variant="success"
            @click="goActionHandler(customers[data.index])"
          >
            {{ $t("Go") }}
          </b-button>
        </div>
      </template>
    </l-table>
    <export-c-s-v
      ref="export-c-s-v"
      :module-name="MODULE_NAME"
    />
    <import-c-s-v
      ref="import-c-s-v"
      :module-name="MODULE_NAME"
      @refetchTable="refetchData"
    />
    <export-c-s-v-batch
      ref="export-c-s-v-batch"
      :module-name="MODULE_NAME"
    />
  </div>
</template>

<script>

import { BLink, BButton } from 'bootstrap-vue'

import LTable from '@/views/components/LTable/LTable.vue'
import customerMenuItems from '@/views/components/sub-menu/main-sub-menu/home-customers'
// eslint-disable-next-line import/no-cycle
import Customers from '@/views/components/filters/fields/Customers'
import ExportCSV from '@/views/components/Modals/ExportCSV.vue'
import ExportCSVBatch from '@/views/components/Modals/ExportCSVBatch.vue'
import ImportCSV from '@/views/components/Modals/ImportCSV.vue'
import jwtDefaultConfig from '@/auth/jwt/jwtDefaultConfig'
import vSelect from 'vue-select'
import config from '../customConfig'
import Actions from '../../../components/Actions.vue'

export default {
  name: 'CustomerList',
  components: {
    BButton,
    vSelect,
    LTable,
    BLink,
    Actions,
    ExportCSV,
    ExportCSVBatch,
    ImportCSV,
  },
  data() {
    return {
      isRedirectCustomerPage: true,
      customerBatchSelected: [],
    }
  },
  computed: {
    customers() {
      return this.$store.state[this.MODULE_NAME].customers
    },
    exportImportButtonItems() {
      return this.$store.state[this.MODULE_NAME].exportImportButtonItems
    },
    Customers() {
      return Customers
    },
  },
  methods: {
    autoSuggestAddParams() {
      // Warning if true pls add f: { is_active: 1,},
      if (!JSON.parse(localStorage.getItem(jwtDefaultConfig.settingCustomerDefaultFormKeyName))?.is_searches_show_inactive_records) {
        return {
          f: {
            is_active: 1,
          },
        }
      }
      return {}
    },
    actionHandler(args) {
      console.log(args)
    },
    pushToSelectedItemByRouter(selectedItem) {
      const { item: { id } } = selectedItem

      this.$router.push(`/customers/${id}/general-info`)
    },
    exportActions({ btnTitle, actionType }) {
      console.log('exportActions', btnTitle, actionType, this.MODULE_NAME)
      this.$store.commit(`${this.MODULE_NAME}/SET_EXPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['export-c-s-v'].show()
    },
    exportActionsForBatch({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_EXPORT_BATCH`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
        ids: this.customerBatchSelected,
      })
      this.$refs['export-c-s-v-batch'].show()
    },
    handleBatchSelected(batch) {
      this.customerBatchSelected = batch
    },
    importActions({ btnTitle, actionType }) {
      this.$store.commit(`${this.MODULE_NAME}/SET_IMPORT`, {
        title: btnTitle,
        actionType,
        query: this.$refs.lTableRef.totalQuery,
      })
      this.$refs['import-c-s-v'].show()
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    // eslint-disable-next-line consistent-return
    getData(data, dataName) {
      const { item: { default_contact } } = data
      if (dataName === 'fullname') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.first_name} ${default_contact.last_name}` : '—'
      }
      if (dataName === 'phone_one') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.phone_one}` : '--—'
      }
    },
    renderSuggestion(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba + (item.default_contact ? `: ${this.getData(suggestion, 'fullname')}` : '')
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba
    },
    getSearchProps() {
      return {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
    },
    getPhoneNumber(obj) {
      const { phone_one_object } = obj

      if (!phone_one_object) {
        return this.getPhoneNumberOld(obj)
      }

      let phoneObj
      try {
        if (typeof phone_one_object === 'object') {
          phoneObj = phone_one_object
        } else {
          phoneObj = JSON.parse(phone_one_object)
        }
      } catch (e) {
        console.error('Failed to parse phone_one_object:', e)
        return '—' // Return early if JSON parsing fails
      }

      if (phoneObj?.formatNational === undefined) {
        if (Object.keys(phoneObj).length < 4) {
          return this.getPhoneNumberOld(obj)
        }
        try {
          const cleanedPhoneObj = JSON.parse(phone_one_object.replace(/\\"/g, '"').slice(1, -1))
          return cleanedPhoneObj?.formatNational ? `+${cleanedPhoneObj.countryCallingCode}${cleanedPhoneObj.formatNational} ${this.getExtVal(cleanedPhoneObj)}` : this.getPhoneNumberOld(obj)
        } catch (e) {
          console.error('Failed to parse cleaned phone_one_object:', e)
          return '—' // Return early if JSON parsing of cleanedPhoneObj fails
        }
      }
      return `+${phoneObj.countryCallingCode}${phoneObj.formatNational} ${this.getExtVal(phoneObj)}`

      // eslint-disable-next-line camelcase
      // if (cell_no) {
      //   // eslint-disable-next-line camelcase
      //   if (cell_no_object) {
      //     // This function can be deleted if formatNational is not used anymore
      //     const phone = JSON.parse(JSON.parse(cell_no_object))
      //     return phone ? `${ph} ${phone.cell_no_ext_val ? `Ext.${phone.cell_no_ext_val}` : ''}` : '—'
      //   }
      //   return ph
      // }
    },
    getPhoneNumberOld(obj) {
      const { phone_one } = obj
      if (phone_one.length > 10) {
        return `(${phone_one.substring(0, 3)})${phone_one.substring(3, 6)}-${phone_one.substring(6, 10)} Ext ${phone_one.substring(10)}`
      }
      return `(${phone_one.substring(0, 3)})${phone_one.substring(3, 6)}-${phone_one.substring(6, 10)}`
    },
    getExtVal(phoneObj) {
      return phoneObj?.phone_ext_val ? (`Ext ${phoneObj?.phone_ext_val}`) : ''
    },
    checkIconType(data) {
      const { status } = data.item ?? false
      if (status === 1) {
        return 'LTickIcon'
      }
      if (status === 2) {
        return 'LNoIcon'
      }
      if (status === 3) {
        return 'LClockIcon'
      }
      return 'LWarningIcon'
    },
    redirectHomeCustomersView(data) {
      if (this.isRedirectCustomerPage) {
        this.$router.push({ name: 'home-customers-view', params: { id: data.id } })
      }
    },
    onSelectAction(value) {
      this.isRedirectCustomerPage = false
      console.log(value)
    },
    goActionHandler(data) {
      switch (data.action.key) {
        case 'startAPo':
          this.$router.push({ name: 'home-orders-purchase-cr-create', query: { supplier_id: data.id } })
          break
        case 'startAQuote':
          this.$router.push({ name: 'home-orders-rental-sales-create', query: { customer_id: data.id } })
          break
        default:
          break
      }
    },
  },
  setup() {
    const MODULE_NAME = 'customers'
    const { menuItems } = customerMenuItems('customers')
    const {
      tableColumns,
      getCustomerName,
      suggestionTableColumns,
      ACCESS_ABILITY_FOR_ACCOUNTS,
      supplierActionList,
    } = config()

    return {
      menuItems,
      MODULE_NAME,
      tableColumns,
      getCustomerName,
      suggestionTableColumns,
      ACCESS_ABILITY_FOR_ACCOUNTS,
      supplierActionList,
    }
  },
}
</script>
<style lang="scss" scoped>
.b-list-group-item-custom {
  padding: 5px !important;
  background: transparent !important;
}

.customer-status-icon {
  fill: red !important;
}
</style>
